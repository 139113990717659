import { ImageField } from "react-admin"
import React from "react"

interface OptimizedImageProps {
  path: string
  size?: number
  fetchSize?: number
}

const OptimizedImageField = ({ size, fetchSize = size, path }: OptimizedImageProps) => {
  const record = {
    url: `https://cdn.andreafisanotti.it/${path}?width=${fetchSize}&height=${fetchSize}`,
  }
  return (
    <ImageField
      record={record}
      source="url"
      sx={{
        "& img": {
          maxWidth: size,
          maxHeight: size,
          objectFit: "cover",
          border: "1px solid #efefef",
          borderRadius: "8px",
        },
      }}
    />
  )
}

export default OptimizedImageField
