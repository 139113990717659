import { Block as BlockIcon, Download as DownloadIcon } from "@mui/icons-material"
import { saveAs } from "file-saver"
import Cookie from "js-cookie"
import { Button } from "@mui/material"
import React from "react"
import { useRecordContext } from "react-admin"

const DownloadExcelButton = () => {
  const record = useRecordContext()
  return !record ? (
    <></>
  ) : (
    <Button
      color="primary"
      disabled={record.draft}
      startIcon={record.draft ? <BlockIcon /> : <DownloadIcon />}
      onClick={(e) => {
        e.stopPropagation()
        e.preventDefault()
        saveAs(
          `${process.env.REACT_APP_BACKEND_URL ?? ""}/api/Orders/${
            record.id
          }/downloadExcel?access_token=${Cookie.get("token")}`,
          `Ordine_${record.id.toString().padStart(6, "0")}_${record.createdAt.substring(
            0,
            19
          )}.xlsx`
        )
      }}
    >
      {record.draft ? "Bozza" : "Scarica Excel"}
    </Button>
  )
}

export default DownloadExcelButton
