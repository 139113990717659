import { Labeled, useGetManyReference, useRecordContext } from "react-admin"

interface CurrentPriceFieldProps {
  label: string
}

const CurrentPriceField = ({ label }: CurrentPriceFieldProps) => {
  const record = useRecordContext()
  const { data } = useGetManyReference("CatalogPrices", {
    target: "productId",
    id: record.id,
    pagination: { page: 1, perPage: 2 },
    sort: { field: "createdAt", order: "DESC" },
  })
  return (
    <Labeled label={label}>
      <span>
        {!data?.[0]?.price ? (
          "-"
        ) : data?.[0]?.discounted ? (
          <>
            <span
              style={{
                textDecoration: "line-through",
              }}
            >
              {new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR" }).format(
                data?.[1]?.price
              )}
            </span>
            <br />
            <span>
              {new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR" }).format(
                data?.[0]?.price
              )}
            </span>
          </>
        ) : (
          new Intl.NumberFormat("it-IT", { style: "currency", currency: "EUR" }).format(
            data?.[0]?.price
          )
        )}
      </span>
    </Labeled>
  )
}
export default CurrentPriceField
