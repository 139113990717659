import { Box, Grid, Typography } from "@mui/material"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanInput,
  Create,
  FileInput,
  FormDataConsumer,
  ImageField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useDataProvider,
  useRecordContext,
} from "react-admin"
import React from "react"
import { convertFileToBase64, uploadFile } from "../../../services/s3"
import OptimizedImage from "../../atoms/OptimizedImage"

const OptionRenderer = () => {
  const record = useRecordContext()
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
    >
      {(record?.attachments ?? []).length > 0 && (
        <OptimizedImage path={record.attachments[0].path ?? ""} fetchSize={50} size={50} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }} ml={2}>
        <div>{record.description}</div>
        <Typography variant="overline">
          Codice: <b>{record.code ?? "-"}</b> - EAN: <b>{record.ean ?? "-"}</b>
        </Typography>
      </Box>
    </Box>
  )
}
const ProductCreate = () => {
  const dataProvider = useDataProvider()
  return (
    <Create
      redirect="show"
      transform={async (data: Record<string, any>) => {
        if (data.aws_file) {
          const supplier = await dataProvider.getOne("Suppliers", { id: data.supplierId })
          const { name, path } = await uploadFile({
            data: await convertFileToBase64(data.aws_file),
            name: `${data.code ?? data.aws_file.rawFile.name}_${new Date().toISOString()}${
              data.aws_file.rawFile.name.match(/\.[0-9a-z]+$/i)[0]
            }`,
            path: supplier.data ? `products/${supplier.data.slug}` : undefined,
          })
          return {
            ...data,
            picture: {
              path,
              filename: name,
            },
          }
        }
        return data
      }}
    >
      <SimpleForm defaultValues={{ type: "parent" }}>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={6}>
            <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
              <SelectInput optionText="name" resettable validate={[required()]} fullWidth />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <FormDataConsumer>
              {({ formData }) =>
                formData.supplierId && (
                  <ReferenceInput
                    source="categoryId"
                    reference="Categories"
                    key="category"
                    alwaysOn
                    filter={{ supplierId: formData.supplierId }}
                  >
                    <SelectInput optionText="name" resettable fullWidth />
                  </ReferenceInput>
                )
              }
            </FormDataConsumer>
          </Grid>
          <Grid item xs={3}>
            <TextInput source="code" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="ean" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="minQty" validate={[required()]} fullWidth min={1} step={1} />
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="boxQty" validate={[required()]} fullWidth min={1} step={1} />
          </Grid>
          <Grid item xs={12}>
            <TextInput
              source="description"
              validate={[required()]}
              fullWidth
              multiline
              minRows={3}
            />
          </Grid>
          <Grid item xs={4}>
            <FormDataConsumer>
              {({ formData }) => (
                <SelectInput
                  disabled={
                    formData.type != "parent"
                      ? !!formData.parentProductId
                      : (formData.productIds ?? []).length > 0
                  }
                  choices={[
                    { id: "child", label: "Figlio" },
                    { id: "parent", label: "Padre" },
                  ]}
                  label="Tipologia"
                  optionText="label"
                  source="type"
                  validate={[required()]}
                  fullWidth
                />
              )}
            </FormDataConsumer>
          </Grid>
          <Grid item xs={8}>
            <FormDataConsumer>
              {({ formData }) =>
                formData.type != "parent" ? (
                  <ReferenceInput
                    source={"parentProductId"}
                    reference="Products"
                    label="Padre"
                    filter={{ supplierId: formData.supplierId }}
                    perPage={2000}
                  >
                    <AutocompleteInput
                      fullWidth
                      disabled={formData.supplierId == null}
                      label="Prodotto padre"
                      optionText={<OptionRenderer />}
                      inputText={(record) => `${record.code ?? ""} - ${record.description ?? ""}`}
                      matchSuggestion={(filter, choice) => {
                        return (
                          choice.description.toLowerCase().includes(filter.toLowerCase()) ||
                          (choice.code ?? "").toLowerCase().includes(filter.toLowerCase()) ||
                          (choice.ean ?? "").toLowerCase().includes(filter.toLowerCase())
                        )
                      }}
                    />
                  </ReferenceInput>
                ) : (
                  <ReferenceArrayInput
                    source="productIds"
                    reference="Products"
                    disabled={formData.supplierId == null}
                    perPage={-1}
                    label="Figli"
                    filter={{ supplierId: formData.supplierId, parentProductId: null }}
                    sort={{ field: "description", order: "ASC" }}
                  >
                    <AutocompleteArrayInput
                      optionText={<OptionRenderer />}
                      disabled={formData.supplierId == null}
                      inputText={(record) => `${record.code ?? ""} - ${record.description ?? ""}`}
                      matchSuggestion={(filter, choice) => {
                        return (
                          choice.description.toLowerCase().includes(filter.toLowerCase()) ||
                          (choice.code ?? "").toLowerCase().includes(filter.toLowerCase()) ||
                          (choice.ean ?? "").toLowerCase().includes(filter.toLowerCase())
                        )
                      }}
                    />
                  </ReferenceArrayInput>
                )
              }
            </FormDataConsumer>
          </Grid>

          <Grid item xs={12}>
            <FileInput
              source="aws_file"
              label="Foto"
              validate={required()}
              accept={`image/*`}
              multiple={false}

              //                  disabled={!formData.type}
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="promo" />
          </Grid>
          <Grid item xs={2}>
            <BooleanInput source="advised" />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default ProductCreate
