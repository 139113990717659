import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin"
import React from "react"
import { Divider } from "@mui/material"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"
import CurrentPriceField from "../../atoms/CurrentPriceField"
import AddToProductParentDialogButton from "../../atoms/AddToCategoryDialogButton"
import EditPropertiesRowButton from "../../atoms/EditPropertiesRowButton"
import { Remove } from "@mui/icons-material"
import CreatePriceDialogButton from "../../atoms/CreatePriceDialogButton"

export const ProductShow = () => (
  <Show>
    <TabbedShowLayout divider={<Divider flexItem />}>
      <TabbedShowLayout.Tab label="Informazioni">
        {" "}
        <ReferenceOneField
          label="Foto"
          reference="Attachments"
          target="originId"
          filter={{ originType: "Product" }}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <OptimizedImageField size={300} />
        </ReferenceOneField>
        <ReferenceField source="supplierId" reference="Suppliers">
          <TextField source="name" emptyText="-" />
        </ReferenceField>
        <ReferenceField source="categoryId" reference="Categories" emptyText="-">
          <TextField source="name" emptyText="-" />
        </ReferenceField>
        <TextField source="ean" emptyText="-" />
        <TextField source="code" emptyText="-" />
        <TextField source="description" emptyText="-" />
        <NumberField source="minQty" emptyText="-" />
        <NumberField source="boxQty" emptyText="-" />
        <BooleanField source="promo" emptyText="No" />
        <BooleanField source="advised" emptyText="No" />
        <ReferenceField
          label="Prodotto padre"
          reference="Products"
          source="parentProductId"
          emptyText="-"
        >
          <TextField source="description" emptyText="-" />
        </ReferenceField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Varianti">
        <AddToProductParentDialogButton />
        <ReferenceManyField
          reference="Products"
          target="parentProductId"
          label={false}
          perPage={10}
          pagination={<Pagination perPage={10} />}
        >
          <Datagrid bulkActionButtons={false}>
            <ReferenceOneField
              label="Foto"
              reference="Attachments"
              target="originId"
              filter={{ originType: "Product" }}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <OptimizedImageField size={50} />
            </ReferenceOneField>
            <TextField source="code" />
            <TextField source="ean" />
            <TextField source="description" />
            <NumberField source="minQty" textAlign="left" />
            <CurrentPriceField label="Prezzo attuale" />
            <EditButton />
            <CreatePriceDialogButton />
            <EditPropertiesRowButton
              payload={(data) => ({ parentProductId: null })}
              label={(data) => "Rimuovi"}
              icon={(data) => <Remove />}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Prezzi">
        <div style={{ marginBottom: "10px" }}>
          <CreateRelatedButton
            resource="CatalogPrices"
            textLabel="Prezzo"
            data={(record) => ({
              productId: record.id,
            })}
          />
        </div>
        <ReferenceManyField
          reference="CatalogPrices"
          target="productId"
          label={false}
          sort={{
            field: "createdAt",
            order: "DESC",
          }}
          pagination={<Pagination />}
        >
          <Datagrid bulkActionButtons={false}>
            <NumberField
              source="price"
              options={{ style: "currency", currency: "EUR" }}
              textAlign="left"
            />
            <ReferenceField source="catalogId" reference="Catalogs" link="show">
              <TextField source="name" />
            </ReferenceField>
            {/*<NumberField source="discount" options={{ style: "percent" }} textAlign="left" />*/}
            <DateField source="createdAt" showTime />
            <EditButton />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export default ProductShow
