import {
  ArrayInput,
  AutocompleteInput,
  Create,
  FormDataConsumer,
  minLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  useGetOne,
  useRecordContext,
} from "react-admin"
import React from "react"
import { Box, Grid, Typography } from "@mui/material"
import OptimizedImage from "../../atoms/OptimizedImage"

const OptionRenderer = () => {
  const record = useRecordContext()
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
    >
      {(record?.attachments ?? []).length > 0 && (
        <OptimizedImage path={record.attachments[0].path ?? ""} fetchSize={50} size={50} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }} ml={2}>
        <div>{record.description}</div>
        <Typography variant="overline">
          Codice: <b>{record.code ?? "-"}</b> - EAN: <b>{record.ean ?? "-"}</b>
        </Typography>
      </Box>
    </Box>
  )
}
const OrderCreate = () => {
  return (
    <Create redirect="show">
      <TabbedForm>
        <TabbedForm.Tab path="info" label="Informazioni">
          <FormDataConsumer>
            {({ formData }) => (
              <Grid container columnSpacing={2} rowSpacing={0}>
                <Grid item xs={12}>
                  <ReferenceInput source="supplierId" reference="Suppliers">
                    <SelectInput
                      optionText="name"
                      resettable
                      validate={[required()]}
                      fullWidth
                      disabled={(formData.items ?? []).length > 0}
                    />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  <ReferenceInput source="customerId" reference="Customers">
                    <SelectInput optionText="name" resettable validate={[required()]} fullWidth />
                  </ReferenceInput>
                </Grid>
                <Grid item xs={6}>
                  {formData.customerId && (
                    <ReferenceInput
                      source="destinationId"
                      reference="Locations"
                      filter={{ originId: formData.customerId, originType: "Customer" }}
                    >
                      <SelectInput
                        optionText={(record) => (
                          <span>
                            {record.firstLine ?? "-"}
                            <br />
                            {record.secondLine ?? "-"}
                            <br /> {record.address} - {record.zip} - {record.city} (
                            {record.province})
                          </span>
                        )}
                        resettable
                        validate={[required()]}
                        fullWidth
                      />
                    </ReferenceInput>
                  )}
                </Grid>
                <Grid item xs={6}>
                  <ReferenceInput source="paymentMethod" reference="PaymentMethods">
                    <SelectInput
                      optionText="name"
                      optionValue="name"
                      resettable
                      validate={[required()]}
                      fullWidth
                    />
                  </ReferenceInput>
                </Grid>
              </Grid>
            )}
          </FormDataConsumer>
        </TabbedForm.Tab>
        <TabbedForm.Tab path="items" label="Prodotti">
          <FormDataConsumer>
            {({ formData }) => (
              <>
                <ArrayInput source="items" validate={[minLength(1, "Almeno 1 elemento")]} fullWidth>
                  <SimpleFormIterator
                    inline
                    fullWidth
                    getItemLabel={(index) => `#${index + 1}`}
                    disableAdd={!formData.supplierId}
                  >
                    <FormDataConsumer>
                      {({
                        formData,
                        scopedFormData,
                        getSource, // A function to get the valid source inside an ArrayInput
                        ...rest
                      }) => (
                        <>
                          <ReferenceInput
                            source={getSource?.("productId") ?? "productId"}
                            reference="Products"
                            filter={{ supplierId: formData.supplierId }}
                            perPage={2000}
                            {...rest}
                          >
                            <AutocompleteInput
                              validate={[required()]}
                              fullWidth
                              label="Prodotto"
                              optionText={<OptionRenderer />}
                              inputText={(record) =>
                                `${record.code ?? ""} - ${record.description ?? ""}`
                              }
                              matchSuggestion={(filter, choice) => {
                                return (
                                  choice.description.toLowerCase().includes(filter.toLowerCase()) ||
                                  (choice.code ?? "")
                                    .toLowerCase()
                                    .includes(filter.toLowerCase()) ||
                                  (choice.ean ?? "").toLowerCase().includes(filter.toLowerCase())
                                )
                              }}
                              disabled={scopedFormData.productId}
                              style={{ width: "70%" }}
                            />
                          </ReferenceInput>

                          {scopedFormData.productId && (
                            <QtyInput data={scopedFormData} getSource={getSource} {...rest} />
                          )}
                        </>
                      )}
                    </FormDataConsumer>
                  </SimpleFormIterator>
                </ArrayInput>
              </>
            )}
          </FormDataConsumer>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  )
}

const QtyInput = ({
  data: fdata,
  getSource,
  ...rest
}: {
  data: Record<string, any>
  getSource: any
}) => {
  const { data } = useGetOne("Products", { id: fdata.productId })
  return (
    <NumberInput
      source={getSource?.("quantity") ?? "quantity"}
      label="Quantità"
      step={data?.minQty}
      min={data?.minQty}
      validate={[required()]}
      defaultValue={data?.minQty}
      disabled={!data}
      {...rest}
    />
  )
}
export default OrderCreate
