import { FilterList, FilterListItem, useGetList } from "react-admin"
import React, { ReactNode } from "react"
import { BoxProps } from "@mui/material"

interface ReferenceFilterListProps extends BoxProps {
  label: string
  icon: ReactNode
  filter?: any
  source: string
  resource: string
  filterField: string
  sort?: { field: string; order: string }
}

const ReferenceFilterList = ({
  filter = {},
  source,
  resource,
  filterField,
  sort = { field: "id", order: "ASC" },
  ...props
}: ReferenceFilterListProps) => {
  const { data } = useGetList(resource, {
    pagination: { page: 1, perPage: 100 },
    sort: sort,
    filter,
  })
  return (
    <FilterList {...props}>
      {(data ?? []).map((element) => (
        <FilterListItem label={element[source]} value={{ [filterField]: element.id }} />
      ))}
    </FilterList>
  )
}

export default ReferenceFilterList
