import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import {
  CreateBase,
  Form,
  NullableBooleanInput,
  NumberInput,
  required,
  SaveButton,
  useGetManyReference,
  useRecordContext,
  useRefresh,
} from "react-admin"
import { Edit } from "@mui/icons-material"
import * as React from "react"

const CreatePriceDialogButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const refresh = useRefresh()
  const { data } = useGetManyReference("CatalogPrices", {
    target: "productId",
    id: record.id,
    pagination: { page: 1, perPage: 1 },
    sort: { field: "createdAt", order: "DESC" },
  })
  return (
    <>
      <Button
        onClick={(e) => {
          setOpen(true)
          e.stopPropagation()
        }}
        startIcon={<Edit />}
      >
        Prezzo
      </Button>
      <Dialog
        onClose={(e: any) => {
          setOpen(false)
        }}
        onClick={(e) => e.stopPropagation()}
        open={open}
        maxWidth="xs"
        fullWidth
      >
        <CreateBase
          resource="CatalogPrices"
          redirect={() => {
            setOpen(false)
            refresh()
            return "Products"
          }}
          record={{ productId: record.id, price: data?.[0]?.price ?? null, discounted: false }}
        >
          <Form>
            <DialogTitle>Cambia prezzo</DialogTitle>
            <DialogContent dividers>
              <NumberInput
                validate={[required()]}
                source="price"
                label="Prezzo"
                min={0}
                step={0.01}
                fullWidth
              />

              <NullableBooleanInput source="discounted" fullWidth />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={(e) => {
                  setOpen(false)
                }}
              >
                Chiudi
              </Button>
              <SaveButton label="Crea" />
            </DialogActions>
          </Form>
        </CreateBase>
      </Dialog>
    </>
  )
}

export default CreatePriceDialogButton
