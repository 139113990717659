import { ReferenceField, Show, SimpleShowLayout, TextField } from "react-admin"
import React from "react"
import { Divider } from "@mui/material"

export const LocationShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <ReferenceField reference="Customers" source="originId">
        <TextField source="name" />
      </ReferenceField>
      <TextField source="firstLine" emptyText="-" />
      <TextField source="secondLine" emptyText="-" />
      <TextField source="address" emptyText="-" />
      <TextField source="city" emptyText="-" />
      <TextField source="zip" emptyText="-" />
      <TextField source="province" emptyText="-" />
      <TextField source="country" emptyText="-" />
      <TextField source="contactPerson" emptyText="-" />
      <TextField source="notes" emptyText="-" />
    </SimpleShowLayout>
  </Show>
)

export default LocationShow
