import { Grid } from "@mui/material"
import { Edit, SimpleForm, TextInput } from "react-admin"
import React from "react"

const PaymentMethodEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12}>
            <TextInput source="name" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default PaymentMethodEdit
