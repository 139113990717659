import {
  NumberField,
  ReferenceOneField,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"
import TotalField from "../../atoms/TotalField"
import React from "react"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import { SupplierAssociationGrid } from "../SupplierAssociation"
import { Divider } from "@mui/material"
import { OrderGrid } from "../Order"
import { CatalogGrid } from "../Catalog"
import { ProductGrid } from "../Product"

const Title = () => {
  const record = useRecordContext()

  return record?.name ?? "Fornitore"
}
export const SupplierShow = () => {
  return (
    <Show title={<Title />}>
      <TabbedShowLayout divider={<Divider flexItem />}>
        <TabbedShowLayout.Tab label="Informazioni">
          <TextField source="name" />
          <NumberField
            source="minQty"
            emptyText="-"
            options={{ style: "currency", currency: "EUR" }}
          />
          <ReferenceOneField
            label="Foto"
            reference="Attachments"
            target="originId"
            filter={{ originType: "Supplier" }}
            sort={{ field: "createdAt", order: "DESC" }}
            emptyText="-"
          >
            <OptimizedImageField size={200} />
          </ReferenceOneField>
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label={
            <TotalField
              reference="Products"
              target="supplierId"
              label="Prodotti"
              parseText={(t) => `Prodotti (${t})`}
            />
          }
          path="products"
        >
          <ProductGrid />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label={
            <TotalField
              reference="Catalogs"
              target="supplierId"
              label="Cataloghi"
              parseText={(t) => `Cataloghi (${t})`}
            />
          }
          path="catalogs"
        >
          <CatalogGrid target="supplierId" />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label={
            <TotalField
              reference="SupplierAssociations"
              target="supplierId"
              label="Clienti"
              parseText={(t) => `Clienti associati (${t})`}
            />
          }
        >
          <SupplierAssociationGrid />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab
          label={
            <TotalField
              reference="Orders"
              target="supplierId"
              label="Ordini"
              parseText={(t) => `Ordini (${t})`}
            />
          }
        >
          <OrderGrid target="supplierId" />
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default SupplierShow
