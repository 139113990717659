import * as React from "react"
import { Button } from "@mui/material"
import { Add } from "@mui/icons-material"
import { useRecordContext, useRedirect } from "react-admin"

interface CreateRelatedButtonProps {
  resource: string
  data?: (record: Record<string, any>) => Record<string, any>
  textLabel: string
  affix?: string
}

const CreateRelatedButton = ({ affix, data, resource, textLabel }: CreateRelatedButtonProps) => {
  const redirect = useRedirect()
  const record = useRecordContext()
  return (
    <Button
      onClick={() =>
        redirect(`/${resource}/create${affix ?? ""}?source=${JSON.stringify(data?.(record) ?? {})}`)
      }
      startIcon={<Add />}
    >
      Aggiungi {textLabel}
    </Button>
  )
}

export default CreateRelatedButton
