import { Show, SimpleShowLayout, TextField } from "react-admin"
import React from "react"
import UrlButton from "../../atoms/UrlButton"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import { Divider } from "@mui/material"

export const AttachmentShow = () => (
  <Show>
    <SimpleShowLayout divider={<Divider flexItem />}>
      <OptimizedImageField size={200} />
      <TextField source="title" />
      <TextField source="type" />
      <TextField source="filename" />
      <UrlButton source="url" label="" />
    </SimpleShowLayout>
  </Show>
)

export default AttachmentShow
