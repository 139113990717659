import {
  Datagrid,
  EditButton,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin"
import React from "react"
import { Divider } from "@mui/material"
import { Remove } from "@mui/icons-material"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import CurrentPriceField from "../../atoms/CurrentPriceField"
import EditPropertiesRowButton from "../../atoms/EditPropertiesRowButton"
import AddToCategoryDialogButton from "../../atoms/AddToCategoryDialogButton"
import CreatePriceDialogButton from "../../atoms/CreatePriceDialogButton"

export const CategoryShow = () => (
  <Show>
    <TabbedShowLayout divider={<Divider flexItem />}>
      <TabbedShowLayout.Tab label="Informazioni">
        <TextField source="name" emptyText="-" />
        <NumberField source="orderNumber" emptyText="-" />
        <ReferenceField reference="Suppliers" source="supplierId">
          <TextField source="name" />
        </ReferenceField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab label="Prodotti">
        <AddToCategoryDialogButton />
        <ReferenceManyField
          perPage={10}
          label={false}
          reference="Products"
          target={"categoryId"}
          pagination={<Pagination perPage={10} />}
        >
          <Datagrid
            rowClick="show"
            rowStyle={(record) => ({
              backgroundColor: record.hidden
                ? "#efefef"
                : record.isDeleted
                ? "#ff67677a"
                : "transparent",
            })}
            bulkActionButtons={false}
          >
            <ReferenceOneField
              label="Foto"
              reference="Attachments"
              target="originId"
              filter={{ originType: "Product" }}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <OptimizedImageField size={50} />
            </ReferenceOneField>
            <TextField source="code" />
            <TextField source="ean" />
            <TextField source="description" />
            <NumberField source="minQty" textAlign="left" />
            <CurrentPriceField label="Prezzo attuale" />
            <EditButton />
            <CreatePriceDialogButton />
            <EditPropertiesRowButton
              payload={(data) => ({ categoryId: null })}
              label={(data) => "Rimuovi"}
              icon={(data) => <Remove />}
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export default CategoryShow
