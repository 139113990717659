import { Divider, Grid, Typography } from "@mui/material"
import {
  CheckboxGroupInput,
  Create,
  FormDataConsumer,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
} from "react-admin"
import React from "react"
import { useLocation } from "react-router-dom"

const SupplierAssociationCreate = () => {
  const location = useLocation()
  return (
    <Create redirect={location?.hash?.replace("#", "") ?? "show"}>
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <FormDataConsumer>
            {({ formData }) =>
              formData.type == "Customer" ? (
                <>
                  <Grid item xs={12}>
                    <ReferenceInput source="customerId" reference="Customers" key="customer">
                      <SelectInput optionText="name" resettable fullWidth disabled />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="supplierId" reference="Suppliers" key="supplier">
                      <SelectInput optionText="name" resettable fullWidth />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="operatorId" reference="Operators" key="operator">
                      <SelectInput
                        optionText={(record) =>
                          `${record.name} ${record.surname} - ${record.email}`
                        }
                        resettable
                        fullWidth
                      />
                    </ReferenceInput>
                  </Grid>
                </>
              ) : formData.type == "Supplier" ? (
                <>
                  <Grid item xs={12}>
                    <ReferenceInput source="supplierId" reference="Suppliers" key="supplier">
                      <SelectInput optionText="name" resettable fullWidth disabled />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="customerId" reference="Customers" key="customer">
                      <SelectInput optionText="name" resettable fullWidth />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="operatorId" reference="Operators" key="operator">
                      <SelectInput
                        optionText={(record) =>
                          `${record.name} ${record.surname} - ${record.email}`
                        }
                        resettable
                        fullWidth
                      />
                    </ReferenceInput>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={12}>
                    <ReferenceInput source="operatorId" reference="Operators" key="operator">
                      <SelectInput
                        optionText={(record) =>
                          `${record.name} ${record.surname} - ${record.email}`
                        }
                        resettable
                        fullWidth
                      />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="supplierId" reference="Suppliers" key="supplier">
                      <SelectInput optionText="name" resettable fullWidth />
                    </ReferenceInput>
                  </Grid>
                  <Grid item xs={6}>
                    <ReferenceInput source="customerId" reference="Customers" key="customer">
                      <SelectInput optionText="name" resettable fullWidth />
                    </ReferenceInput>
                  </Grid>
                </>
              )
            }
          </FormDataConsumer>
          <Grid item xs={12}>
            <Typography variant="h6">Pagamenti</Typography>
            <Divider />
          </Grid>
          <Grid item xs={6} mb={2}>
            <ReferenceArrayInput source="paymentMethodIds" reference="PaymentMethods">
              <CheckboxGroupInput optionText="name" row={false} />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default SupplierAssociationCreate
