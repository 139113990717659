import {
  Datagrid,
  DateField,
  Pagination,
  ReferenceManyField,
  TextField,
  useResourceContext,
} from "react-admin"
import React from "react"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"

interface CatalogGridProps {
  target: string
}

const CatalogGrid = ({ target }: CatalogGridProps) => {
  const resource = useResourceContext()
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <CreateRelatedButton
          resource="Catalogs"
          textLabel="Cataloghi"
          affix="/info"
          data={(record) => ({
            [`${resource.substring(0, resource.length - 1).toLowerCase()}Id`]: record.id,
          })}
        />
      </div>
      <div style={{ border: "1px solid #efefef" }}>
        <ReferenceManyField
          reference="Catalogs"
          target={target}
          label={false}
          pagination={<Pagination />}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <Datagrid rowClick="show" bulkActionButtons={false}>
            <TextField source="name" />
            <DateField source="createdAt" showTime />
          </Datagrid>
        </ReferenceManyField>
      </div>
    </>
  )
}

export default CatalogGrid
