import { Divider, Grid, Typography } from "@mui/material"
import {
  CheckboxGroupInput,
  Create,
  FileInput,
  ImageField,
  NumberInput,
  ReferenceArrayInput,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"
import { convertFileToBase64, uploadFile } from "../../../services/s3"

const SupplierCreate = () => {
  return (
    <Create
      redirect="show"
      transform={async (data: Record<string, any>) => {
        if (data.aws_file) {
          const { name, path } = await uploadFile({
            data: await convertFileToBase64(data.aws_file),
            name: data.aws_file.rawFile.name,
          })
          return {
            ...data,
            picture: {
              path,
              filename: name,
            },
          }
        }
        return data
      }}
    >
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Info Fornitore</Typography>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="name" validate={[required()]} fullWidth />
            <NumberInput source="minQty" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <FileInput
              source="aws_file"
              label="Foto"
              validate={required()}
              accept={`image/*`}
              multiple={false}

              //                  disabled={!formData.type}
            >
              <ImageField source="src" title="title" />
            </FileInput>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Pagamenti</Typography>
            <Divider />
          </Grid>
          <Grid item xs={6} mb={2}>
            <ReferenceArrayInput source="paymentMethodIds" reference="PaymentMethods">
              <CheckboxGroupInput optionText="name" row={false} />
            </ReferenceArrayInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default SupplierCreate
