import * as React from "react"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material"
import { Add } from "@mui/icons-material"
import {
  AutocompleteArrayInput,
  Form,
  ReferenceArrayInput,
  SaveButton,
  useRecordContext,
  useRefresh,
  useUpdateMany,
} from "react-admin"
import OptimizedImage from "../OptimizedImage"

const OptionRenderer = () => {
  const record = useRecordContext()
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
    >
      {(record?.attachments ?? []).length > 0 && (
        <OptimizedImage path={record.attachments[0].path ?? ""} fetchSize={50} size={50} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }} ml={2}>
        <div>{record.description}</div>
        <Typography variant="overline">
          Codice: <b>{record.code ?? "-"}</b> - EAN: <b>{record.ean ?? "-"}</b>
        </Typography>
      </Box>
    </Box>
  )
}
const AddToCategoryDialogButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const refresh = useRefresh()
  const [update, { isLoading, error }] = useUpdateMany()
  return (
    <>
      <Button
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
        startIcon={<Add />}
      >
        Aggiungi Prodotti
      </Button>
      <Dialog
        onClose={(e: any) => {
          setOpen(false)
        }}
        onClick={(e) => e.stopPropagation()}
        open={open}
        maxWidth="md"
        fullWidth
      >
        <Form
          onSubmit={(data) => {
            if (data.productIds && data.productIds.length > 0)
              update("Products", { ids: data.productIds, data: { categoryId: record.id } })
                .then(() => {
                  setOpen(false)
                  refresh()
                })
                .finally(() => {
                  refresh()
                })
          }}
        >
          <DialogTitle>Scegli prodotti</DialogTitle>
          <DialogContent dividers>
            <ReferenceArrayInput
              source="productIds"
              reference="Products"
              perPage={-1}
              filter={{ supplierId: record.supplierId, categoryId: null }}
              sort={{ field: "description", order: "ASC" }}
            >
              <AutocompleteArrayInput
                optionText={<OptionRenderer />}
                inputText={(record) => `${record.code ?? ""} - ${record.description ?? ""}`}
                matchSuggestion={(filter, choice) => {
                  return (
                    choice.description.toLowerCase().includes(filter.toLowerCase()) ||
                    (choice.code ?? "").toLowerCase().includes(filter.toLowerCase()) ||
                    (choice.ean ?? "").toLowerCase().includes(filter.toLowerCase())
                  )
                }}
              />
            </ReferenceArrayInput>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={(e) => {
                setOpen(false)
              }}
            >
              Chiudi
            </Button>
            <SaveButton />
          </DialogActions>
        </Form>
      </Dialog>
    </>
  )
}

export default AddToCategoryDialogButton
