import {
  Datagrid,
  FunctionField,
  NumberField,
  RaRecord,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  Show,
  TabbedShowLayout,
  TextField,
  TopToolbar,
  WrapperField,
} from "react-admin"
import React from "react"
import TotalField from "../../atoms/TotalField"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import DownloadExcelButton from "../../atoms/DownloadExcelButton"
import { Divider } from "@mui/material"

export const OrderShow = () => (
  <Show
    actions={
      <TopToolbar>
        <DownloadExcelButton />
      </TopToolbar>
    }
  >
    <TabbedShowLayout divider={<Divider flexItem />}>
      <TabbedShowLayout.Tab label="Informazioni">
        <ReferenceField source="customerId" reference="Customers" link="show">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="paymentMethod" />
        <ReferenceField source="destinationId" reference="Locations" link="show">
          <WrapperField>
            <TextField source="firstLine" />
            <br />
            <TextField source="secondLine" />
            <br />
            <TextField source="address" />
            <span> - </span>
            <TextField source="city" /> <span> - </span>
            <TextField source="province" /> <span> - </span>
            <TextField source="zip" /> <br />
            <TextField source="contactPerson" />
          </WrapperField>
        </ReferenceField>
        <ReferenceField source="supplierId" reference="Suppliers" link="show">
          <WrapperField>
            <ReferenceOneField
              label="Foto"
              reference="Attachments"
              target="originId"
              filter={{ originType: "Supplier" }}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <OptimizedImageField size={50} />
            </ReferenceOneField>
            <TextField source="name" />
          </WrapperField>
        </ReferenceField>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        label={
          <TotalField
            reference="OrderLines"
            target="orderId"
            label="Righe"
            parseText={(t) => `Righe (${t})`}
          />
        }
        path="products"
      >
        <ReferenceManyField reference="OrderLines" target="orderId" label={false}>
          <Datagrid bulkActionButtons={false}>
            <ReferenceField reference="Products" source="productId">
              <ReferenceOneField
                label="Foto"
                reference="Attachments"
                target="originId"
                filter={{ originType: "Product" }}
                sort={{ field: "createdAt", order: "DESC" }}
              >
                <OptimizedImageField size={50} />
              </ReferenceOneField>
            </ReferenceField>

            <TextField source="code" />
            <TextField source="ean" />
            <ReferenceField reference="Products" source="productId">
              <TextField source="description" />
            </ReferenceField>
            <NumberField source="price" options={{ style: "currency", currency: "EUR" }} />
            <NumberField source="qty" />
            <FunctionField
              label="Importo totale"
              render={(record: RaRecord) =>
                (record.qty * record.price).toLocaleString("it", {
                  style: "currency",
                  currency: "EUR",
                })
              }
            />
          </Datagrid>
        </ReferenceManyField>
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export default OrderShow
