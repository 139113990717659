import { Labeled, useGetManyReference, useRecordContext } from "react-admin"
import { CircularProgress } from "@mui/material"

interface TotalFieldProps {
  reference: string
  label: string
  target: string
  filter?: Record<string, any>
  labeled?: boolean
  parseText?: (text: string) => string
}

const TotalField = ({
  reference,
  target,
  label,
  filter,
  labeled = true,
  parseText,
}: TotalFieldProps) => {
  const record = useRecordContext()
  const { total } = useGetManyReference(reference, {
    target,
    id: record.id,
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter,
  })
  return labeled ? (
    <Labeled label={label}>
      <span>{parseText ? parseText(total?.toString() ?? "-") : total ?? "-"}</span>
    </Labeled>
  ) : (
    <span>
      {parseText ? parseText(total?.toString() ?? "-") : total ?? <CircularProgress size={14} />}
    </span>
  )
}

export default TotalField
