import { Grid } from "@mui/material"
import { Edit, NumberInput, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin"
import React from "react"

const CategoryEdit = () => {
  return (
    <Edit redirect="show">
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={5}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <NumberInput source="orderNumber" defaultValue={0} min={0} fullWidth />
          </Grid>
          <Grid item xs={5}>
            <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
              <SelectInput optionText="name" resettable fullWidth />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default CategoryEdit
