import { Divider, Grid, Typography } from "@mui/material"
import {
  Edit,
  maxLength,
  minLength,
  PasswordInput,
  regex,
  required,
  SimpleForm,
  TextInput,
} from "react-admin"
import React from "react"

const CustomerEdit = () => {
  return (
    <Edit
      redirect="show"
      transform={(data: Record<string, any>) => ({ ...data, password: data.clearPassword })}
    >
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Info Cliente</Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="name" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <TextInput source="vat" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={3}>
            <TextInput source="fiscalCode" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={2}>
            <TextInput source="sdiCode" fullWidth />
          </Grid>
          <Grid item xs={5}>
            <TextInput source="pec" fullWidth />
          </Grid>
          <Grid item xs={5}>
            <TextInput source="address" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <TextInput source="city" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={1.5}>
            <TextInput
              source="zip"
              validate={[
                required(),
                minLength(5, "Troppo corto"),
                maxLength(5, "Troppo lungo"),
                regex(/^[0-9]*$/, "Non corretto"),
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={1.5}>
            <TextInput
              source="province"
              validate={[
                required(),
                minLength(2, "troppo corto"),
                maxLength(2, "troppo lungo"),
                regex(/^[a-zA-Z]*$/, "Non corretto"),
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="contactPerson" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="notes" multiline rows={5} fullWidth />
          </Grid>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Utenza</Typography>
            <Divider />
          </Grid>

          <Grid item xs={2}>
            <TextInput source="telephone" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="email" type="email" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={4}>
            <PasswordInput source="clearPassword" validate={[required(), minLength(8)]} fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default CustomerEdit
