import { Datagrid, EditButton, List, SearchInput, TextField } from "react-admin"

const CustomerList = () => {
  return (
    <List filters={[<SearchInput source="q" alwaysOn key="search" />]} exporter={false}>
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="surname" />
        <TextField source="email" />
        <TextField source="telephone" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
export default CustomerList
