import {
  AutocompleteInput,
  Edit,
  NullableBooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
} from "react-admin"
import { Grid } from "@mui/material"
import React from "react"

const CatalogPriceEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={6}>
            <ReferenceInput source="catalogId" reference="Catalogs" key="catalog" alwaysOn>
              <SelectInput optionText="name" resettable fullWidth disabled />
            </ReferenceInput>
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source="productId" reference="Products" key="product" alwaysOn>
              <AutocompleteInput
                fullWidth
                label="Prodotto"
                inputText={(record) => `${record.code} - ${record.ean} - ${record.description}`}
                optionText={(record) => (
                  <span>
                    Codice: {record.code ?? "-"}
                    <br />
                    EAN: {record.ean ?? "-"}
                    <br />
                    {record.description}
                  </span>
                )}
                disabled
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={3}>
            <NumberInput source="price" fullWidth validate={[required()]} />
          </Grid>
          <Grid item xs={3}>
            <NullableBooleanInput source="discounted" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
export default CatalogPriceEdit
