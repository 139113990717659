import {
  Datagrid,
  List,
  ReferenceManyCount,
  ReferenceOneField,
  SearchInput,
  TextField,
} from "react-admin"
import OptimizedImageField from "../../atoms/OptimizedImageField"

const SupplierList = () => {
  return (
    <List filters={[<SearchInput source="q" alwaysOn key="search" />]} exporter={false}>
      <Datagrid rowClick="show" bulkActionButtons={false}>
        <ReferenceOneField
          label="Foto"
          reference="Attachments"
          target="originId"
          filter={{ originType: "Supplier" }}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <OptimizedImageField size={50} fetchSize={150} />
        </ReferenceOneField>
        <TextField source="name" />
        <ReferenceManyCount label="Cataloghi" reference="Catalogs" target="supplierId" />
        <ReferenceManyCount label="Prodotti" reference="Products" target="supplierId" />
        <ReferenceManyCount
          label="Ordini"
          reference="Orders"
          target="supplierId"
          filter={{ draft: false }}
        />
        <ReferenceManyCount label="Clienti" reference="SupplierAssociations" target="supplierId" />
      </Datagrid>
    </List>
  )
}
export default SupplierList
