import { Divider, Typography } from "@mui/material"
import React from "react"
import { Login, LoginForm } from "react-admin"

const CustomLoginForm = (props: any) => (
  <div>
    <Typography variant="body1" align="center">
      <img
        src={process.env.PUBLIC_URL + "/logo.png"}
        alt="AVATAR"
        width={120}
        style={{ marginTop: 10, marginBottom: 10 }}
      />
    </Typography>
    <Typography variant="h6" align="center" gutterBottom={true}>
      Console Amministrazione
    </Typography>
    <Divider variant="middle" />
    <LoginForm {...props} />
  </div>
)

const LoginPage = (props: any) => (
  <Login {...props} backgroundImage={process.env.PUBLIC_URL + "/mountain-bg.png"}>
    <CustomLoginForm {...props} />
  </Login>
)

export default LoginPage
