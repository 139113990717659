import { Button, useRecordContext } from "react-admin"
import React from "react"
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material"

interface UrlButtonProps {
  source: string
  label: string
}
const UrlButton = ({ source }: UrlButtonProps) => {
  const record = useRecordContext()

  return !record ? (
    <span></span>
  ) : (
    <Button label="Visualizza" component="a" href={record[source]}>
      <OpenInNewIcon />
    </Button>
  )
}

export default UrlButton
