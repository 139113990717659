import {
  Datagrid,
  DateField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  TextField,
  useResourceContext,
} from "react-admin"
import SumField from "../../atoms/SumField"
import DownloadExcelButton from "../../atoms/DownloadExcelButton"
import React from "react"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"

interface OrderGridProps {
  target: string
}

const OrderGrid = ({ target }: OrderGridProps) => {
  const resource = useResourceContext()
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <CreateRelatedButton
          resource="Orders"
          textLabel="Ordini"
          affix="/info"
          data={(record) => ({
            [`${resource.substring(0, resource.length - 1).toLowerCase()}Id`]: record.id,
          })}
        />
      </div>
      <div style={{ border: "1px solid #efefef" }}>
        <ReferenceManyField
          reference="Orders"
          target={target}
          label={false}
          pagination={<Pagination />}
        >
          <Datagrid
            rowClick="show"
            bulkActionButtons={false}
            rowStyle={(record, index) => ({
              backgroundColor: record.draft ? "#efefef" : "transparent",
            })}
            isRowExpandable={(record) => !record.draft}
            expand={({ record }) => (
              <div style={{ border: "1px solid #efefef" }}>
                <ReferenceManyField reference="OrderLines" target="orderId" label="Righe ordine">
                  <Datagrid bulkActionButtons={false}>
                    <ReferenceField reference="Products" source="productId">
                      <ReferenceOneField
                        label="Foto"
                        reference="Attachments"
                        target="originId"
                        filter={{ originType: "Product" }}
                        sort={{ field: "createdAt", order: "DESC" }}
                      >
                        <OptimizedImageField size={50} />
                      </ReferenceOneField>
                    </ReferenceField>

                    <TextField source="code" />
                    <TextField source="ean" />
                    <ReferenceField reference="Products" source="productId">
                      <TextField source="description" />
                    </ReferenceField>
                    <NumberField source="qty" />
                    <NumberField source="price" options={{ style: "currency", currency: "EUR" }} />
                  </Datagrid>
                </ReferenceManyField>
              </div>
            )}
          >
            <DateField source="createdAt" showTime />
            {resource !== "Suppliers" && (
              <ReferenceField source="supplierId" reference="Suppliers">
                <TextField source="name" />
              </ReferenceField>
            )}
            {resource !== "Customers" && (
              <ReferenceField source="customerId" reference="Customers">
                <TextField source="name" />
              </ReferenceField>
            )}
            <SumField
              reference="OrderLines"
              target="orderId"
              label="Prodotti"
              operation={(d) => d.qty}
            />
            <SumField
              reference="OrderLines"
              target="orderId"
              label="Prodotti"
              prefix="€ "
              digits={2}
              operation={(d) => d.qty * d.price}
            />
            <DownloadExcelButton />
          </Datagrid>
        </ReferenceManyField>
      </div>
    </>
  )
}

export default OrderGrid
