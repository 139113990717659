import { Button } from "@mui/material"
import {
  Confirm,
  RaRecord,
  useRecordContext,
  useRefresh,
  useResourceContext,
  useUpdate,
} from "react-admin"
import { Edit as EditIcon } from "@mui/icons-material"
import { useState } from "react"

interface EditPropertiesRowButtonProps {
  payload: (data: RaRecord) => Record<string, any>
  label: (data: RaRecord) => string
  icon?: (data: RaRecord) => JSX.Element
}

const EditPropertiesRowButton = ({ payload, label, icon }: EditPropertiesRowButtonProps) => {
  const record = useRecordContext()
  const resource = useResourceContext()
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()

  const [update, { isLoading, error }] = useUpdate(resource, {
    id: record.id,
    data: payload(record),
    previousData: record,
  })
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    update().finally(() => {
      refresh()
      setOpen(false)
    })
  }
  return (
    <>
      <Button
        startIcon={icon?.(record) ?? <EditIcon />}
        disabled={isLoading || !!error}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(true)
        }}
      >
        {label(record)}
      </Button>
      <Confirm
        onClick={(e) => e.stopPropagation()}
        isOpen={open}
        loading={isLoading}
        title="Aggiorna dati"
        content="Sei sicuro di aggiornare questi dati?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default EditPropertiesRowButton
