import { Divider, Grid, Typography } from "@mui/material"
import { Edit, minLength, PasswordInput, required, SimpleForm, TextInput } from "react-admin"

const OperatorEdit = () => {
  return (
    <Edit transform={(data: Record<string, any>) => ({ ...data, password: data.clearPassword })}>
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12} mb={2}>
            <Typography variant="h6">Info Operatore</Typography>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="surname" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={6}>
            <TextInput source="name" validate={[required()]} fullWidth />
          </Grid>
          <Grid item xs={5}>
            <TextInput source="email" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <TextInput source="telephone" fullWidth />
          </Grid>

          <Grid item xs={5}>
            <PasswordInput source="clearPassword" validate={[required(), minLength(8)]} fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}

export default OperatorEdit
