import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material"
import { useGetList } from "react-admin"

interface TotalCardProps {
  resource: string
  label: string
  buttonLabel: string
  filter?: Record<string, any>
  parseText?: (text: string) => string
  path: string
}

const TotalCard = ({ path, buttonLabel, resource, label, filter, parseText }: TotalCardProps) => {
  const { total, isLoading } = useGetList(resource, {
    pagination: { page: 1, perPage: 1 },
    sort: { field: "id", order: "DESC" },
    filter,
  })
  return (
    <Card>
      {isLoading && <LinearProgress variant="indeterminate" />}
      <CardContent>
        <Typography variant="body1" component="div">
          {label}
        </Typography>
        <Typography variant="h4" component="div">
          {parseText ? parseText(total?.toString() ?? "-") : total ?? "-"}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions style={{ justifyContent: "end" }}>
        <Button size="small" color="primary" component="a" href={path}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  )
}

export default TotalCard
