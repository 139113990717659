import {
  Datagrid,
  EditButton,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin"
import React from "react"
import TotalField from "../../atoms/TotalField"

const CategoryList = () => {
  return (
    <List
      filters={[
        <SearchInput source="q" alwaysOn key="search" />,
        <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
          <SelectInput optionText="name" resettable />
        </ReferenceInput>,
      ]}
      exporter={false}
      sort={{ field: "orderNumber", order: "ASC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <ReferenceField source="supplierId" reference="Suppliers">
          <TextField source="name" />
        </ReferenceField>
        <TotalField reference="Products" target="categoryId" label="Prodotti" />
        <NumberField source="orderNumber" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
export default CategoryList
