import { Labeled, useGetManyReference, useRecordContext } from "react-admin"

interface SumFieldProps {
  reference: string
  label: string
  target: string
  filter?: Record<string, any>
  labeled?: boolean
  operation: (d: Record<string, any>) => number
  parseText?: (text: string) => string
  digits?: number
  prefix?: string
}

const SumField = ({
  reference,
  target,
  label,
  filter,
  labeled = true,
  parseText,
  operation,
  prefix,
  digits,
}: SumFieldProps) => {
  const record = useRecordContext()
  const { data } = useGetManyReference(reference, {
    target,
    id: record.id,
    pagination: { page: 1, perPage: 200 },
    sort: { field: "id", order: "DESC" },
    filter,
  })
  const total = (data ?? []).reduce((a: number, b: Record<string, any>) => operation(b) + a, 0)
  const content = (
    <span>
      {prefix ?? ""}
      {parseText
        ? parseText(total?.toFixed(digits ?? 0) ?? "-")
        : total?.toFixed(digits ?? 0) ?? "-"}
    </span>
  )
  return labeled ? <Labeled label={label}>{content}</Labeled> : content
}

export default SumField
