import { Button } from "@mui/material"
import {
  Confirm,
  ListControllerResult,
  useListContext,
  useRefresh,
  useUpdateMany,
} from "react-admin"
import { Edit as EditIcon } from "@mui/icons-material"
import { useState } from "react"

interface EditPropertiesButtonProps {
  payload: (listContext: ListControllerResult<any>) => Record<string, any>
  label: (listContext: ListControllerResult<any>) => string
  icon?: (listContext: ListControllerResult<any>) => JSX.Element
}

const EditPropertiesButton = ({ payload, label, icon }: EditPropertiesButtonProps) => {
  const listContext = useListContext()
  const [open, setOpen] = useState(false)
  const refresh = useRefresh()

  const { selectedIds, resource, onUnselectItems } = listContext
  const [update, { isLoading, error }] = useUpdateMany(resource, {
    ids: selectedIds,
    data: payload(listContext),
  })
  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    setOpen(false)

    update().finally(() => {
      onUnselectItems()
      refresh()
    })
  }
  return (
    <>
      <Button
        startIcon={icon?.(listContext) ?? <EditIcon />}
        disabled={isLoading || !!error}
        onClick={handleClick}
      >
        {label(listContext)}
      </Button>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Aggiorna dati"
        content="Sei sicuro di aggiornare questi dati?"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  )
}

export default EditPropertiesButton
