import {
  BulkDeleteButton,
  Datagrid,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  List,
  NumberField,
  ReferenceField,
  ReferenceOneField,
  TextField,
} from "react-admin"
import React from "react"
import CurrentPriceField from "../../atoms/CurrentPriceField"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import EditPropertiesButton from "../../atoms/EditPropertiesButton"
import {
  Category as CategoryIcon,
  Groups as GroupsIcon,
  Sell as SellIcon,
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material"
import { Card, CardContent } from "@mui/material"
import ReferenceFilterList from "../../atoms/ReferenceFilterList"
import CreatePriceDialogButton from "../../atoms/CreatePriceDialogButton"

const PostFilterSidebar = () => (
  <Card sx={{ order: -1, mr: 2, width: 250, my: 1.5 }} id="filter-list">
    <CardContent>
      <FilterLiveSearch />
      <FilterList label="Impostazioni" icon={<VisibilityIcon />}>
        <FilterListItem label="Mostra nascosti" value={{ hidden: true }} />
        <FilterListItem label="Mostra cancellati" value={{ isDeleted: true }} />
      </FilterList>
      <FilterList label="Tipologie" icon={<SellIcon />}>
        <FilterListItem label="Consigliati" value={{ advised: true }} />
        <FilterListItem label="Promozione" value={{ promo: true }} />
      </FilterList>
      <ReferenceFilterList
        label="Fornitori"
        icon={<GroupsIcon />}
        resource="Suppliers"
        filterField="supplierId"
        source="name"
        sort={{ field: "name", order: "ASC" }}
      />
      <ReferenceFilterList
        label="Categorie"
        icon={<CategoryIcon />}
        resource="Categories"
        filterField="categoryId"
        source="name"
        sort={{ field: "name", order: "ASC" }}
      />
    </CardContent>
  </Card>
)

const ProductList = () => {
  return (
    <List aside={<PostFilterSidebar />} exporter={false}>
      <Datagrid
        rowClick="show"
        rowStyle={(record) => ({
          backgroundColor: record.hidden
            ? "#efefef"
            : record.isDeleted
            ? "#ff67677a"
            : record.advised
            ? "rgba(103,138,255,0.48)"
            : record.promo
            ? "rgba(255,183,0,0.48)"
            : "transparent",
        })}
        bulkActionButtons={
          <>
            <EditPropertiesButton
              payload={(listContext) => ({ hidden: !(listContext.filterValues.hidden ?? false) })}
              label={(listContext) =>
                listContext.filterValues.hidden ?? false ? "Mostra" : "Nascondi"
              }
              icon={(listContext) =>
                listContext.filterValues.hidden ?? false ? (
                  <VisibilityIcon />
                ) : (
                  <VisibilityOffIcon />
                )
              }
            />
            {/* default bulk delete action */}
            <BulkDeleteButton />
          </>
        }
      >
        <ReferenceOneField
          label="Foto"
          reference="Attachments"
          target="originId"
          filter={{ originType: "Product" }}
          sort={{ field: "createdAt", order: "DESC" }}
        >
          <OptimizedImageField size={50} />
        </ReferenceOneField>
        <ReferenceField source="supplierId" reference="Suppliers">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="code" />
        <TextField source="ean" />
        <TextField source="description" />
        <NumberField source="minQty" textAlign="left" />
        <CurrentPriceField label="Prezzo attuale" />
        <CreatePriceDialogButton />
      </Datagrid>
    </List>
  )
}
export default ProductList
