import { useGetList } from "react-admin"
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Divider,
  LinearProgress,
  Typography,
} from "@mui/material"

interface SumCardProps {
  resource: string
  label: string
  filter?: Record<string, any>
  operation: (d: Record<string, any>) => number
  digits?: number
  options?: any
  buttonLabel: string
  path: string
}

const SumCard = ({
  buttonLabel,
  path,
  options,
  resource,
  label,
  filter,
  operation,
  digits,
}: SumCardProps) => {
  const { data, isLoading } = useGetList(resource, {
    pagination: { page: 1, perPage: 300 },
    sort: { field: "id", order: "DESC" },
    filter,
  })
  const total = (data ?? []).reduce((a: number, b: Record<string, any>) => operation(b) + a, 0)
  return (
    <Card>
      {isLoading && <LinearProgress variant="indeterminate" />}
      <CardContent>
        <Typography variant="body1" component="div">
          {label}
        </Typography>
        <Typography variant="h4" component="div">
          {options ? total?.toLocaleString("it", options) : total?.toFixed(digits ?? 0) ?? "-"}
        </Typography>
      </CardContent>
      <Divider />
      <CardActions style={{ justifyContent: "end" }}>
        <Button size="small" color="primary" component="a" href={path}>
          {buttonLabel}
        </Button>
      </CardActions>
    </Card>
  )
}

export default SumCard
