import { Grid } from "@mui/material"
import { Create, SimpleForm, TextInput } from "react-admin"
import React from "react"

const PaymentMethodCreate = () => {
  return (
    <Create redirect="show">
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={12}>
            <TextInput source="name" fullWidth />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default PaymentMethodCreate
