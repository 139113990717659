import { Edit, ReferenceInput, SelectInput, SimpleForm, TextInput } from "react-admin"
import { Grid } from "@mui/material"
import React from "react"

const CatalogEdit = () => {
  return (
    <Edit>
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={6}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={6}>
            <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
              <SelectInput optionText="name" resettable fullWidth disabled />
            </ReferenceInput>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  )
}
export default CatalogEdit
