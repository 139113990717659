import { Button, useRecordContext } from "react-admin"
import React from "react"
import { OpenInNew as OpenInNewIcon } from "@mui/icons-material"

interface WebLoginButtonProps {
  source: string
  label: string
}

const WebLoginButton = () => {
  const record = useRecordContext()

  return !record ? (
    <span></span>
  ) : (
    <Button
      label="Entra sull'app"
      variant="contained"
      onClick={() => {
        window.open(
          `https://app.andreafisanotti.it/?email=${record.email}&password=${record.clearPassword}`,
          "_blank"
        )
      }}
    >
      <OpenInNewIcon />
    </Button>
  )
}

export default WebLoginButton
