import { Divider, Grid } from "@mui/material"
import {
  Create,
  maxLength,
  minLength,
  ReferenceInput,
  regex,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
} from "react-admin"
import React from "react"

const LocationCreate = () => (
  <Create redirect="show">
    <SimpleForm defaultValues={{ country: "Italia" }}>
      <Grid container columnSpacing={2} rowSpacing={0}>
        <Grid item xs={12}>
          <ReferenceInput source="originId" reference="Customers" key="customer">
            <SelectInput
              optionText={(record) => `${record.name} - ${record.email}`}
              resettable
              fullWidth
              disabled
              label="Cliente"
            />
          </ReferenceInput>
        </Grid>
        <Grid item xs={12} mb={3}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="firstLine" validate={[required()]} fullWidth />
          <TextInput source="secondLine" fullWidth />
          <TextInput source="address" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={5}>
          <TextInput source="city" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="zip"
            validate={[
              required(),
              minLength(5, "Troppo corto"),
              maxLength(5, "Troppo lungo"),
              regex(/^[0-9]*$/, "Non corretto"),
            ]}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextInput
            source="province"
            validate={[
              required(),
              minLength(2, "troppo corto"),
              maxLength(2, "troppo lungo"),
              regex(/^[a-zA-Z]*$/, "Non corretto"),
            ]}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <TextInput source="country" validate={[required()]} fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="contactPerson" fullWidth />
        </Grid>
        <Grid item xs={12}>
          <TextInput source="notes" fullWidth multiline minRows={4} />
        </Grid>
      </Grid>
    </SimpleForm>
  </Create>
)

export default LocationCreate
