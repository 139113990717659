import * as React from "react"
import { Admin, Resource } from "react-admin"
import authProvider from "./providers/authProvider"
import dataProvider from "./providers/dataProvider"
import polyglotI18nProvider from "ra-i18n-polyglot"
import { italianMessages } from "./i18n/it"
import LoginPage from "./components/organisms/Login"
import Dashboard from "./components/organisms/Dashboard"
import CustomTheme from "./components/organisms/Layout/theme"
import CustomLayout from "./components/organisms/Layout"
import {
  CustomerCreate,
  CustomerEdit,
  CustomerList,
  CustomerShow,
} from "./components/resources/Customer"
import {
  AssignmentInd as AssignmentIndIcon,
  Attachment as AttachmentIcon,
  Category as CategoryIcon,
  Euro as EuroIcon,
  Groups as GroupsIcon,
  Inventory as InventoryIcon,
  People as PeopleIcon,
} from "@mui/icons-material"
import {
  SupplierCreate,
  SupplierEdit,
  SupplierList,
  SupplierShow,
} from "./components/resources/Supplier"
import {
  ProductCreate,
  ProductEdit,
  ProductList,
  ProductShow,
} from "./components/resources/Product"
import {
  OperatorCreate,
  OperatorEdit,
  OperatorList,
  OperatorShow,
} from "./components/resources/Operator"
import { AttachmentShow } from "./components/resources/Attachment"
import { OrderCreate, OrderList, OrderShow } from "./components/resources/Order"
import {
  SupplierAssociationCreate,
  SupplierAssociationEdit,
} from "./components/resources/SupplierAssociation"
import { LocationCreate, LocationEdit, LocationShow } from "./components/resources/Location"
import {
  PaymentMethodCreate,
  PaymentMethodEdit,
  PaymentMethodList,
} from "./components/resources/PaymentMethod"
import "./App.css"
import {
  CategoryCreate,
  CategoryEdit,
  CategoryList,
  CategoryShow,
} from "./components/resources/Category"
import { CatalogCreate, CatalogEdit, CatalogShow } from "./components/resources/Catalog"
import { CatalogPriceCreate, CatalogPriceEdit } from "./components/resources/CatalogPrice"
import { BrowserRouter } from "react-router-dom"

const i18nProvider = polyglotI18nProvider(italianMessages, "it")
const url = process.env.REACT_APP_BACKEND_URL ?? ""

const App = () => (
  <BrowserRouter>
    <Admin
      title="Dashboard"
      loginPage={LoginPage}
      dashboard={Dashboard}
      theme={CustomTheme}
      layout={CustomLayout}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider(`${url}/api`)}
      authProvider={authProvider(`${url}/api`)}
    >
      <Resource
        name="Customers"
        list={CustomerList}
        create={CustomerCreate}
        show={CustomerShow}
        edit={CustomerEdit}
        icon={PeopleIcon}
      />
      <Resource
        name="Suppliers"
        list={SupplierList}
        create={SupplierCreate}
        show={SupplierShow}
        edit={SupplierEdit}
        icon={GroupsIcon}
      />
      <Resource
        name="Operators"
        list={OperatorList}
        create={OperatorCreate}
        show={OperatorShow}
        edit={OperatorEdit}
        icon={AssignmentIndIcon}
      />
      <Resource
        name="Products"
        list={ProductList}
        create={ProductCreate}
        show={ProductShow}
        edit={ProductEdit}
        icon={InventoryIcon}
      />
      <Resource
        name="Categories"
        create={CategoryCreate}
        edit={CategoryEdit}
        list={CategoryList}
        show={CategoryShow}
        icon={CategoryIcon}
      />
      <Resource name="Catalogs" create={CatalogCreate} edit={CatalogEdit} show={CatalogShow} />
      <Resource name="Attachments" show={AttachmentShow} icon={AttachmentIcon} />
      <Resource name="CatalogPrices" edit={CatalogPriceEdit} create={CatalogPriceCreate} />
      <Resource name="Locations" create={LocationCreate} edit={LocationEdit} show={LocationShow} />
      <Resource name="Orders" list={OrderList} show={OrderShow} create={OrderCreate} />
      <Resource name="OrderLines" />
      <Resource
        name="PaymentMethods"
        icon={EuroIcon}
        create={PaymentMethodCreate}
        edit={PaymentMethodEdit}
        list={PaymentMethodList}
      />
      <Resource
        name="SupplierAssociations"
        create={SupplierAssociationCreate}
        edit={SupplierAssociationEdit}
      />
    </Admin>
  </BrowserRouter>
)

export default App
