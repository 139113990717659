import {
  Datagrid,
  DateField,
  List,
  NullableBooleanInput,
  NumberField,
  ReferenceField,
  ReferenceInput,
  ReferenceManyField,
  ReferenceOneField,
  SearchInput,
  SelectInput,
  TextField,
} from "react-admin"
import React from "react"
import SumField from "../../atoms/SumField"
import DownloadExcelButton from "../../atoms/DownloadExcelButton"
import OptimizedImageField from "../../atoms/OptimizedImageField"

const OrderList = () => {
  return (
    <List
      exporter={false}
      filters={[
        <SearchInput source="q" alwaysOn key="search" />,
        <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
          <SelectInput optionText="name" resettable />
        </ReferenceInput>,
        <ReferenceInput source="customerId" reference="Customers" key="customer" alwaysOn>
          <SelectInput optionText="name" resettable />
        </ReferenceInput>,
        <NullableBooleanInput source="draft" alwaysOn />,
      ]}
      sort={{ field: "createdAt", order: "DESC" }}
    >
      <Datagrid
        rowClick="show"
        bulkActionButtons={false}
        rowStyle={(record, index) => ({
          backgroundColor: record.draft ? "#efefef" : "transparent",
        })}
        isRowExpandable={(record) => !record.draft}
        expand={({ record }) => (
          <div style={{ border: "1px solid #efefef" }}>
            <ReferenceManyField reference="OrderLines" target="orderId" label="Righe ordine">
              <Datagrid bulkActionButtons={false}>
                <ReferenceField reference="Products" source="productId">
                  <ReferenceOneField
                    label="Foto"
                    reference="Attachments"
                    target="originId"
                    filter={{ originType: "Product" }}
                    sort={{ field: "createdAt", order: "DESC" }}
                  >
                    <OptimizedImageField size={50} />
                  </ReferenceOneField>
                </ReferenceField>

                <TextField source="code" />
                <TextField source="ean" />
                <ReferenceField reference="Products" source="productId">
                  <TextField source="description" />
                </ReferenceField>
                <NumberField source="qty" />
                <NumberField source="price" options={{ style: "currency", currency: "EUR" }} />
              </Datagrid>
            </ReferenceManyField>
          </div>
        )}
      >
        <DateField source="createdAt" showTime />
        <ReferenceField source="supplierId" reference="Suppliers">
          <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="customerId" reference="Customers">
          <TextField source="name" />
        </ReferenceField>
        <SumField
          reference="OrderLines"
          target="orderId"
          label="Prodotti"
          operation={(d) => d.qty}
        />
        <SumField
          reference="OrderLines"
          target="orderId"
          label="Prodotti"
          prefix="€ "
          digits={2}
          operation={(d) => d.qty * d.price}
        />
        <DownloadExcelButton />
      </Datagrid>
    </List>
  )
}
export default OrderList
