import CreateRelatedButton from "../../atoms/CreateRelatedButton"
import {
  ChipField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  Pagination,
  ReferenceArrayField,
  ReferenceField,
  ReferenceManyField,
  SingleFieldList,
  TextField,
  useRecordContext,
  useResourceContext,
} from "react-admin"
import React from "react"

const SupplierAssociationGrid = () => {
  const record = useRecordContext()
  const resource = useResourceContext()
  return (
    <>
      <div>
        <CreateRelatedButton
          resource="SupplierAssociations"
          textLabel="Associazione"
          data={(crecord) => ({
            [`${resource.substring(0, resource.length - 1).toLowerCase()}Id`]: crecord.id,
            type: resource.substring(0, resource.length - 1),
          })}
        />
      </div>
      <ReferenceManyField
        record={record}
        reference="SupplierAssociations"
        target={`${resource.substring(0, resource.length - 1).toLowerCase()}Id`}
        label={false}
        pagination={<Pagination />}
      >
        <Datagrid bulkActionButtons={false}>
          {resource !== "Suppliers" && (
            <ReferenceField source="supplierId" reference="Suppliers">
              <TextField source="name" />
            </ReferenceField>
          )}
          {resource !== "Customers" && (
            <ReferenceField source="customerId" reference="Customers">
              <TextField source="name" />
            </ReferenceField>
          )}
          {resource !== "Operators" && (
            <ReferenceField source="operatorId" reference="Operators">
              <FunctionField render={(record: any) => record.surname + " " + record.name} />
            </ReferenceField>
          )}
          <ReferenceArrayField label="Metodi" reference="PaymentMethods" source="paymentMethodIds">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ReferenceArrayField>
          <EditButton />
          <DeleteWithConfirmButton redirect={false} />
        </Datagrid>
      </ReferenceManyField>
    </>
  )
}
export default SupplierAssociationGrid
