import { Box, Grid } from "@mui/material"
import { Title } from "react-admin"
import TotalCard from "../../atoms/TotalCard"
import SumCard from "../../atoms/SumCard"

const Dashboard = () => (
  <Box my={2}>
    <Title title="ra.page.dashboard" />
    <Grid container columnSpacing={2} rowSpacing={0}>
      <Grid item xs={3}>
        <TotalCard
          resource="Orders"
          label="Ordini"
          buttonLabel="Visualizza ordini"
          filter={{ draft: false }}
          path={"/#/Orders"}
        />
      </Grid>
      <Grid item xs={3}>
        <TotalCard
          resource="Suppliers"
          label="Fornitori"
          buttonLabel="Visualizza fornitori"
          path={"/#/Suppliers"}
        />
      </Grid>
      <Grid item xs={3}>
        <TotalCard
          resource="Customers"
          label="Clienti"
          buttonLabel="Visualizza clienti"
          path={"/#/Customers"}
        />
      </Grid>
      <Grid item xs={3}>
        <SumCard
          resource="OrderLines"
          label="Fatturato"
          path="/#/Orders"
          buttonLabel="Visualizza ordini"
          operation={(d) => (d.price ?? 0) * (d.qty ?? 0)}
          options={{
            style: "currency",
            currency: "EUR",
          }}
        />
      </Grid>
    </Grid>
  </Box>
)

export default Dashboard
