import { Show, TabbedShowLayout, TextField } from "react-admin"
import React from "react"
import TotalField from "../../atoms/TotalField"
import { SupplierAssociationGrid } from "../SupplierAssociation"
import { Divider } from "@mui/material"

export const CustomerShow = () => (
  <Show>
    <TabbedShowLayout divider={<Divider flexItem />}>
      <TabbedShowLayout.Tab label="Informazioni">
        <TextField source="surname" emptyText="-" />
        <TextField source="name" emptyText="-" />
        <TextField source="email" emptyText="-" />
        <TextField source="telephone" emptyText="-" />
        <TextField source="clearPassword" emptyText="-" />
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        path="associations"
        label={
          <TotalField
            reference="SupplierAssociations"
            target="operatorId"
            label="Fornitori-Clienti associati"
            parseText={(t) => `Fornitori-Clienti associati (${t})`}
          />
        }
      >
        <SupplierAssociationGrid />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export default CustomerShow
