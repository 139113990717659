import { Box, Grid, Typography } from "@mui/material"
import {
  AutocompleteArrayInput,
  Create,
  FormDataConsumer,
  NumberInput,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  useRecordContext,
} from "react-admin"
import React from "react"
import OptimizedImage from "../../atoms/OptimizedImage"

const OptionRenderer = () => {
  const record = useRecordContext()
  return (
    <Box
      sx={{ display: "flex", flexDirection: "row", justifyContent: "start", alignItems: "center" }}
    >
      {(record?.attachments ?? []).length > 0 && (
        <OptimizedImage path={record.attachments[0].path ?? ""} fetchSize={50} size={50} />
      )}
      <Box sx={{ display: "flex", flexDirection: "column" }} ml={2}>
        <div>{record.description}</div>
        <Typography variant="overline">
          Codice: <b>{record.code ?? "-"}</b> - EAN: <b>{record.ean ?? "-"}</b>
        </Typography>
      </Box>
    </Box>
  )
}
const CategoryCreate = () => {
  return (
    <Create redirect="list">
      <SimpleForm>
        <Grid container columnSpacing={2} rowSpacing={0}>
          <Grid item xs={5}>
            <TextInput source="name" fullWidth />
          </Grid>
          <Grid item xs={2}>
            <NumberInput source="orderNumber" defaultValue={0} min={0} fullWidth />
          </Grid>

          <FormDataConsumer>
            {({ formData }) => (
              <>
                <Grid item xs={5}>
                  <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
                    <SelectInput
                      optionText="name"
                      resettable
                      fullWidth
                      disabled={(formData.productIds ?? []).length > 0}
                    />
                  </ReferenceInput>
                </Grid>
                {/*{!!formData.supplierId && (
                  <Grid item xs={12}>
                    <ReferenceArrayInput
                      source="productIds"
                      reference="Products"
                      perPage={-1}
                      filter={{ supplierId: formData.supplierId, categoryId: null }}
                      sort={{ field: "description", order: "ASC" }}
                    >
                      <AutocompleteArrayInput
                        optionText={<OptionRenderer />}
                        inputText={(record) => `${record.code ?? ""} - ${record.description ?? ""}`}
                        matchSuggestion={(filter, choice) => {
                          return (
                            choice.description.toLowerCase().includes(filter.toLowerCase()) ||
                            (choice.code ?? "").toLowerCase().includes(filter.toLowerCase()) ||
                            (choice.ean ?? "").toLowerCase().includes(filter.toLowerCase())
                          )
                        }}
                      />
                    </ReferenceArrayInput>
                  </Grid>
                )}*/}
              </>
            )}
          </FormDataConsumer>
        </Grid>
      </SimpleForm>
    </Create>
  )
}

export default CategoryCreate
