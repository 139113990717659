import {
  ArrayInput,
  AutocompleteInput,
  Create,
  FormDataConsumer,
  minLength,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from "react-admin"
import { Grid } from "@mui/material"
import React from "react"

const CatalogCreate = () => {
  return (
    <Create>
      <TabbedForm>
        <TabbedForm.Tab label="Informazioni" path="info">
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid item xs={6}>
              <TextInput source="name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <ReferenceInput source="supplierId" reference="Suppliers" key="supplier" alwaysOn>
                <SelectInput optionText="name" resettable fullWidth />
              </ReferenceInput>
            </Grid>
          </Grid>
        </TabbedForm.Tab>
        <TabbedForm.Tab label="Prezzi" path="pricess">
          <FormDataConsumer>
            {({ formData }) => (
              <ArrayInput source="items" validate={[minLength(1, "Almeno 1 elemento")]} fullWidth>
                <SimpleFormIterator
                  inline
                  fullWidth
                  getItemLabel={(index) => `#${index + 1}`}
                  disableAdd={!formData.supplierId}
                >
                  <ReferenceInput
                    source="productId"
                    reference="Products"
                    filter={{ supplierId: formData.supplierId }}
                    perPage={2000}
                  >
                    <AutocompleteInput
                      validate={[required()]}
                      fullWidth
                      label="Prodotto"
                      inputText={(record) =>
                        `${record.code} - ${record.ean} - ${record.description}`
                      }
                      optionText={(record) => (
                        <span>
                          Codice: {record.code ?? "-"}
                          <br />
                          EAN: {record.ean ?? "-"}
                          <br />
                          {record.description}
                        </span>
                      )}
                      style={{ width: "70%" }}
                    />
                  </ReferenceInput>
                  <NumberInput source="price" label="Prezzo" min={0} step={1} />
                </SimpleFormIterator>
              </ArrayInput>
            )}
          </FormDataConsumer>
        </TabbedForm.Tab>
      </TabbedForm>
    </Create>
  )
}
export default CatalogCreate
