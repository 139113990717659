import { ImageField, useRecordContext, useResourceContext } from "react-admin"
import React from "react"

interface OptimizedImageFieldProps {
  size?: number
  fetchSize?: number
}

const OptimizedImageField = ({ size, fetchSize = size }: OptimizedImageFieldProps) => {
  let record = useRecordContext()
  const resource = useResourceContext()
  record = {
    ...record,
    url: `https://cdn.andreafisanotti.it/${record["path"]}?width=${fetchSize}&height=${fetchSize}`,
  }
  return (
    <ImageField
      record={record}
      resource={resource}
      source="url"
      sx={{
        "& img": {
          maxWidth: size,
          maxHeight: size,
          objectFit: "cover",
          border: "1px solid #efefef",
          borderRadius: "8px",
        },
      }}
    />
  )
}

export default OptimizedImageField
