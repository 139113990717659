import * as AWS from "aws-sdk"
import getUuid from "uuid-by-string"
import { Buffer } from "buffer"

AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID ?? "",
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY ?? "",
})

export const uploadFile = ({
  data,
  name,
  path = "attachments",
  mutableName = false,
}: {
  data: string
  name: string
  path?: string
  mutableName?: boolean
}) => {
  return new Promise<{ name: string; path: string }>((resolve, reject) => {
    const regex = /^data:.*\/\w+;base64,/
    const base64Data: Buffer = Buffer.from(data.replace(regex, ""), "base64")
    const type = data.split(";")[0].split("/")[1]

    const extracted = name.match(/[^\\]*\.(\w+)$/)
    const filename = !mutableName
      ? name
      : `${extracted ? `${getUuid(new Date().toISOString())}.${extracted?.[1]}` : name}`
    const params: AWS.S3.PutObjectRequest = {
      ACL: "public-read",
      Body: base64Data,
      ContentEncoding: "base64", // required
      ContentType: type,
      Bucket: process.env.REACT_APP_AWS_S3_BUCKET ?? "",
      Key: `${path}/${filename}`,
    }

    const s3 = new AWS.S3({
      params: { Bucket: process.env.REACT_APP_AWS_S3_BUCKET ?? "" },
      region: process.env.REACT_APP_AWS_REGION ?? "",
    })
    try {
      s3.upload(params)
        .promise()
        .then(() => {
          resolve({ path: `${path}/${filename}`, name: filename })
        })
    } catch (e) {
      reject(e)
    }
  })
}

export const convertFileToBase64 = (file: any): Promise<string> =>
  new Promise<any>((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => resolve(reader.result)
    reader.onerror = reject
    reader.readAsDataURL(file.rawFile)
  })
