import {
  Datagrid,
  DateField,
  EditButton,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  ReferenceOneField,
  Show,
  TabbedShowLayout,
  TextField,
} from "react-admin"
import React from "react"
import OptimizedImageField from "../../atoms/OptimizedImageField"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"

const CatalogShow = () => {
  return (
    <Show>
      <TabbedShowLayout>
        <TabbedShowLayout.Tab label="Informazioni">
          <TextField source="name" />
          <DateField source="createdAt" showTime />
        </TabbedShowLayout.Tab>
        <TabbedShowLayout.Tab label="Prezzi">
          <ReferenceManyField
            reference="CatalogPrices"
            target="catalogId"
            label={false}
            perPage={10}
            pagination={<Pagination />}
          >
            <div style={{ marginBottom: "10px" }}>
              <CreateRelatedButton
                resource="CatalogPrices"
                textLabel="Prezzo"
                data={(record) => ({
                  catalogId: record.id,
                })}
              />
            </div>
            <Datagrid bulkActionButtons={false}>
              <ReferenceField reference="Products" source="productId" label="Foto">
                <ReferenceOneField
                  label="Foto"
                  reference="Attachments"
                  target="originId"
                  filter={{ originType: "Product" }}
                  sort={{ field: "createdAt", order: "DESC" }}
                >
                  <OptimizedImageField size={50} />
                </ReferenceOneField>
              </ReferenceField>
              <ReferenceField reference="Products" source="productId" label="Codice">
                <TextField source="code" />
              </ReferenceField>
              <ReferenceField reference="Products" source="productId" label="Descrizione">
                <TextField source="description" />
              </ReferenceField>
              <NumberField
                source="price"
                options={{
                  style: "currency",
                  currency: "EUR",
                }}
              />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </TabbedShowLayout.Tab>
      </TabbedShowLayout>
    </Show>
  )
}
export default CatalogShow
