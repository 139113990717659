import { Typography } from "@mui/material"
import React from "react"
import { AppBar, AppBarProps } from "react-admin"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  title: {
    flex: 1,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  spacer: {
    flex: 1,
  },
  logo: {
    maxHeight: "50px",
    paddingTop: "5px",
    paddingBottom: "5px",
  },
})
const CustomAppBar = ({ ...props }: AppBarProps) => {
  const classes = useStyles()
  return (
    <AppBar {...props} elevation={0}>
      <img src={process.env.PUBLIC_URL + "/logo.png"} alt="logo" className={classes?.logo} />
      <Typography variant="h6" color="inherit">
        Andrea Fisanotti
      </Typography>
      <span className={classes?.spacer} />
      <Typography
        variant="overline"
        color="inherit"
        className={classes?.title}
        id="react-admin-title"
        align="center"
        fontWeight="bold"
        fontSize={16}
        letterSpacing={1.2}
      />
      <span className={classes?.spacer} />
    </AppBar>
  )
}

export default CustomAppBar
