import {
  Datagrid,
  EditButton,
  FunctionField,
  Labeled,
  Pagination,
  RaRecord,
  ReferenceManyField,
  Show,
  TabbedShowLayout,
  TextField,
  useRecordContext,
} from "react-admin"
import React from "react"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"
import TotalField from "../../atoms/TotalField"
import { SupplierAssociationGrid } from "../SupplierAssociation"
import { Box, Divider, Grid, Typography } from "@mui/material"
import { OrderGrid } from "../Order"
import WebLoginButton from "../../atoms/WebLoginButton"

const Title = () => {
  const record = useRecordContext()
  return record?.name ?? "Cliente"
}
export const CustomerShow = () => (
  <Show title={<Title />}>
    <TabbedShowLayout divider={<Divider flexItem />}>
      <TabbedShowLayout.Tab label="Informazioni">
        <Box py={1}>
          <Typography variant="h5" gutterBottom>
            Informazioni
          </Typography>
          <Grid container columnSpacing={2} rowSpacing={0}>
            <Grid item xs={4} flexDirection="column" display="flex">
              <Labeled>
                <TextField source="name" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="address" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="city" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="zip" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="province" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="telephone" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="contactPerson" emptyText="-" />
              </Labeled>
            </Grid>

            <Grid item xs={4} flexDirection="column" display="flex">
              <Labeled>
                <TextField source="vat" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="fiscalCode" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="sdiCode" emptyText="-" />
              </Labeled>

              <Box py={1}>
                <Divider />
              </Box>
              <Labeled>
                <TextField source="pec" emptyText="-" />
              </Labeled>
            </Grid>
            <Grid item xs={4}>
              <Box
                flexDirection="column"
                display="flex"
                bgcolor="#efefef"
                borderRadius={5}
                p={1}
                pb={2}
                px={2}
              >
                <Typography variant="h6" gutterBottom>
                  Utenza
                </Typography>
                <Labeled>
                  <TextField source="email" emptyText="-" />
                </Labeled>
                <Box py={1}>
                  <Divider />
                </Box>
                <Labeled>
                  <TextField source="clearPassword" emptyText="-" />
                </Labeled>
                <Box mt={2}>
                  <WebLoginButton />
                </Box>
              </Box>
            </Grid>
          </Grid>
          <Labeled>
            <TextField source="notes" emptyText="-" />
          </Labeled>
        </Box>
        <Box py={1}>
          <Typography variant="h5" gutterBottom>
            <TotalField
              reference="Locations"
              target="originId"
              label="Sedi"
              parseText={(t) => `Sedi (${t})`}
              filter={{ originType: "Customer" }}
            />
          </Typography>
          <div>
            <CreateRelatedButton
              resource="Locations"
              textLabel="Sede"
              data={(record) => ({ originType: "Customer", originId: record.id })}
            />
          </div>
          <ReferenceManyField
            reference="Locations"
            target="originId"
            filter={{ originType: "Customer" }}
            label={false}
            pagination={<Pagination />}
          >
            <Datagrid bulkActionButtons={false} rowClick="show">
              <FunctionField
                label="Intestazione"
                render={(record: RaRecord) => (
                  <span>
                    {record.firstLine ?? "-"} <br /> {record.secondLine ?? "-"}
                  </span>
                )}
                emptyText="-"
              />
              <TextField source="address" emptyText="-" />
              <FunctionField
                label="Città"
                render={(record: RaRecord) => (
                  <span>
                    {record.zip ?? "-"} - {record.city ?? "-"} ({record.province ?? "-"})
                  </span>
                )}
                emptyText="-"
              />
              <TextField source="country" emptyText="-" />
              <TextField source="contactPerson" emptyText="-" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Box>
        <Box py={1}>
          <Typography variant="h5" gutterBottom>
            <TotalField
              reference="SupplierAssociations"
              target="customerId"
              label="Fornitori associati"
              parseText={(t) => `Fornitori associati (${t})`}
            />
          </Typography>
          <SupplierAssociationGrid />
        </Box>
      </TabbedShowLayout.Tab>
      <TabbedShowLayout.Tab
        path="orders"
        label={
          <TotalField
            reference="Orders"
            target="customerId"
            label="Ordini"
            parseText={(t) => `Ordini (${t})`}
          />
        }
      >
        <OrderGrid target="customerId" />
      </TabbedShowLayout.Tab>
    </TabbedShowLayout>
  </Show>
)

export default CustomerShow
