import {
  BulkDeleteButton,
  Datagrid,
  NumberField,
  Pagination,
  ReferenceManyField,
  ReferenceOneField,
  TextField,
  useResourceContext,
} from "react-admin"
import CurrentPriceField from "../../atoms/CurrentPriceField"
import React from "react"
import CreateRelatedButton from "../../atoms/CreateRelatedButton"
import EditPropertiesButton from "../../atoms/EditPropertiesButton"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material"
import OptimizedImageField from "../../atoms/OptimizedImageField"

const ProductGrid = () => {
  const resource = useResourceContext()
  return (
    <>
      <div style={{ marginBottom: "10px" }}>
        <CreateRelatedButton
          resource="Products"
          textLabel="Prodotto"
          data={(record) => ({
            [`${resource.substring(0, resource.length - 1).toLowerCase()}Id`]: record.id,
          })}
        />
      </div>
      <div style={{ border: "1px solid #efefef" }}>
        <ReferenceManyField
          reference="Products"
          target={`${resource.substring(0, resource.length - 1).toLowerCase()}Id`}
          label={false}
          pagination={<Pagination />}
        >
          <Datagrid
            rowClick="show"
            rowStyle={(record) => ({
              backgroundColor: record.hidden
                ? "#efefef"
                : record.isDeleted
                ? "#ff67677a"
                : record.advised
                ? "rgba(103,138,255,0.48)"
                : record.promo
                ? "rgba(255,183,0,0.48)"
                : "transparent",
            })}
            bulkActionButtons={
              <>
                <EditPropertiesButton
                  payload={(listContext) => ({
                    hidden: !(listContext.filterValues.hidden ?? false),
                  })}
                  label={(listContext) =>
                    listContext.filterValues.hidden ?? false ? "Mostra" : "Nascondi"
                  }
                  icon={(listContext) =>
                    listContext.filterValues.hidden ?? false ? (
                      <VisibilityIcon />
                    ) : (
                      <VisibilityOffIcon />
                    )
                  }
                />
                {/* default bulk delete action */}
                <BulkDeleteButton />
              </>
            }
          >
            <ReferenceOneField
              label="Foto"
              reference="Attachments"
              target="originId"
              filter={{ originType: "Product" }}
              sort={{ field: "createdAt", order: "DESC" }}
            >
              <OptimizedImageField size={50} />
            </ReferenceOneField>
            <TextField source="code" />
            <TextField source="ean" />
            <TextField source="description" />
            <NumberField source="minQty" textAlign="left" />
            <CurrentPriceField label="Prezzo attuale" />
          </Datagrid>
        </ReferenceManyField>
      </div>
    </>
  )
}

export default ProductGrid
