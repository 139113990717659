import { createTheme } from "@mui/material/styles"
import red from "@mui/material/colors/red"
import { defaultTheme } from "react-admin"

const CustomTheme = createTheme({
  ...defaultTheme,
  palette: {
    primary: {
      500: "#062aba",
    },
    secondary: {
      A400: "#062aba",
    },
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      "MontSerrat",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Arial",
      "sans-serif",
    ].join(","),
  },
})

export default CustomTheme
