import { AuthProvider, UserIdentity } from "react-admin"
import Cookie from "js-cookie"

const authProvider = (API_URL: string): AuthProvider => ({
  login: ({ username, password }) => {
    const request = new Request(`${API_URL}/Operators/login?include=user`, {
      method: "POST",
      body: JSON.stringify({ username, password }),
      headers: new Headers({ "Content-Type": "application/json" }),
    })
    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }
        return response.json()
      })
      .then(({ id, ttl, user: { id: userId, name, surname, username } }) => {
        Cookie.set("token", id, { expires: 10 })
        if (userId) Cookie.set("userId", userId, { expires: 10 })
        if (userId) Cookie.set("username", username, { expires: 10 })
        return Promise.resolve()
      })
  },
  checkAuth: async () => {
    const token = Cookie.get("token")
    //if (!token) return Promise.reject()
    //const res = await fetch(new Request(`${API_URL}/AccessTokens/${token}/exists`))
    return token ? Promise.resolve() : Promise.reject()
  },
  checkError: (error) => {
    const status = error.status
    if (status === 401 || status === 403) {
      Cookie.remove("token")
      Cookie.remove("userId")
      Cookie.remove("username")
      return Promise.reject()
    }
    return Promise.resolve()
  },
  logout: async () => {
    const res = await fetch(
      new Request(`${API_URL}/Operators/logout` + "?access_token=" + Cookie.get("token"), {
        method: "POST",
        body: null,
        headers: new Headers({ "Content-Type": "application/json" }),
      })
    )
    Cookie.remove("token")
    Cookie.remove("userId")
    Cookie.remove("username")
    return Promise.resolve("/login")
  },
  getIdentity: (): Promise<UserIdentity> => {
    try {
      const id = Cookie.get("userId")
      const name = Cookie.get("username")
      return Promise.resolve({
        id: id ?? 0,
        fullName: name ?? "Utente",
      })
    } catch (error) {
      console.error(error)
      return Promise.reject(error)
    }
  },
  getPermissions: () => {
    const role = Cookie.get("username")
    return role ? Promise.resolve(role) : Promise.reject()
  },
})
export default authProvider
