import { TranslationMessages } from "react-admin"

export const italianMessages = (): TranslationMessages => ({
  ra: {
    configurable: {
      configureMode: "",
      customize: "",
      Datagrid: {
        title: "",
        unlabeled: "",
      },
      inspector: {
        title: "",
        content: "",
        hideAll: "",
        showAll: "",
        reset: "",
      },
      SimpleForm: {
        title: "",
        unlabeled: "",
      },
      SimpleList: {
        title: "",
        primaryText: "",
        secondaryText: "",
        tertiaryText: "",
      },
    },
    saved_queries: {
      help: "",
      label: "",
      query_name: "",
      new_dialog_title: "",
      new_label: "",
      remove_message: "",
      remove_dialog_title: "",
      remove_label: "",
      remove_label_with_name: "",
    },
    action: {
      close_menu: "",
      create_item: "",
      move_down: "",
      move_up: "",
      open_menu: "",
      select_all: "",
      select_row: "",
      update: "",
      clear_array_input: "",
      open: "",
      remove_all_filters: "",
      select_columns: "",
      toggle_theme: "",
      add_filter: "Aggiungi un filtro",
      add: "Aggiungi",
      back: "Indietro",
      bulk_actions: "%{smart_count} selezionati",
      cancel: "Annulla",
      clear_input_value: "Svuota il modulo",
      clone: "Duplica",
      confirm: "Conferma",
      create: "Crea",
      delete: "Cancella",
      edit: "Modifica",
      export: "Esporta",
      list: "Elenco",
      refresh: "Aggiorna",
      remove_filter: "Rimuovi questo filtro",
      remove: "Rimuovi",
      save: "Salva",
      search: "Ricerca",
      show: "Mostra",
      sort: "Ordina",
      undo: "Annulla",
      expand: "Espandi",
      close: "Chiudi",
      unselect: "Deseleziona",
    },
    boolean: {
      null: "",
      true: "Si",
      false: "No",
    },
    page: {
      create: "Aggiungi %{name}",
      dashboard: "Dashboard",
      edit: "%{name} %{id}",
      error: "Qualcosa non ha funzionato",
      list: "Lista %{name}",
      loading: "Caricamento in corso",
      not_found: "Non trovato",
      show: "%{name} %{id}",
      empty: "Non esiste alcun record",
      invite: "Aggiungi un nuovo record",
    },
    input: {
      password: {
        toggle_hidden: "",
        toggle_visible: "",
      },
      file: {
        upload_several: "Trascina i files da caricare, oppure clicca per selezionare.",
        upload_single: "Trascina il file da caricare, oppure clicca per selezionarlo.",
      },
      image: {
        upload_several: "Trascina le immagini da caricare, oppure clicca per selezionarle.",
        upload_single: "Trascina l'immagine da caricare, oppure clicca per selezionarla.",
      },
      references: {
        all_missing: "Impossibile trovare i riferimenti associati.",
        many_missing: "Almeno uno dei riferimenti associati non sembra più disponibile.",
        single_missing: "Il riferimento associato non sembra più disponibile.",
      },
    },
    message: {
      auth_error: "",
      clear_array_input: "",
      bulk_update_content: "",
      bulk_update_title: "",
      about: "Informazioni",
      are_you_sure: "Sei sicuro ?",
      bulk_delete_content:
        "Sei sicuro di voler cancellare questo %{name}? |||| Sei sicuro di voler eliminare questi %{smart_count}?",
      bulk_delete_title: "Delete %{name} |||| Delete %{smart_count} %{name} items",
      delete_content: "Sei sicuro di cancellare questo elemento?",
      delete_title: "Cancella %{name} #%{id}",
      details: "Dettagli",
      error: "Un errore locale è occorso e la tua richiesta non è stata completata.",
      invalid_form: "Il modulo non è valido. Si prega di verificare la presenza di errori.",
      loading: "La pagina si sta caricando, solo un momento per favore",
      no: "No",
      not_found: "Hai inserito un URL errato, oppure hai cliccato un link errato",
      yes: "Si",
      unsaved_changes: "Stai lasciando la pagina, le modifiche non salvate verranno perse",
    },
    navigation: {
      page: "Pagina",
      current_page: "Pagina corrente",
      first: "Prima",
      last: "Ultima",
      partial_page_range_info: "prev",
      previous: "Precedente",
      skip_nav: "",
      no_results: "Nessun risultato trovato",
      no_more_results:
        "La pagina numero %{page} è fuori dell'intervallo. Prova la pagina precedente.",
      page_out_of_boundaries: "Il numero di pagina %{page} è fuori dei limiti",
      page_out_from_end: "Fine della paginazione",
      page_out_from_begin: "Il numero di pagina deve essere maggiore di 1",
      page_range_info: "%{offsetBegin}-%{offsetEnd} di %{total}",
      page_rows_per_page: "Righe per pagina",
      next: "Successivo",
      prev: "Precedente",
    },
    auth: {
      auth_check_error: "",
      user_menu: "Profile",
      username: "Nome utente",
      password: "Password",
      sign_in: "Login",
      sign_in_error: "Autenticazione fallita, riprovare.",
      logout: "Disconnessione",
    },
    notification: {
      i18n_error: "",
      not_authorized: "",
      updated: "Record aggiornato |||| %{smart_count} records aggiornati",
      created: "Record creato",
      deleted: "Record eliminato |||| %{smart_count} records eliminati",
      bad_item: "Record errato",
      item_doesnt_exist: "Record inesistente",
      http_error: "Errore di comunicazione con il server dati",
      data_provider_error: "Errore del data provider. Controlla la console per i dettagli.",
      canceled: "Azione annullata",
      logged_out: "La sessione è scaduta. Effettua nuovamente l'accesso.",
    },
    validation: {
      required: "Campo obbligatorio",
      minLength: "Deve essere lungo %{min} caratteri almeno",
      maxLength: "Deve essere lungo %{max} caratteri al massimo",
      minValue: "Deve essere almeno %{min}",
      maxValue: "Deve essere al massimo %{max}",
      number: "Deve essere un numero",
      email: "Deve essere un valido indirizzo email",
      oneOf: "Deve essere uno di: %{options}",
      regex: "Deve rispettare il formato (espressione regolare): %{pattern}",
    },
    sort: {
      sort_by: "Ordina per",
      ASC: "ASC",
      DESC: "DESC",
    },
  },
  resources: {
    Customers: {
      name: "Cliente |||| Clienti",
      fields: {
        name: "Nome",
        vat: "Partita IVA",
        fiscalCode: "Codice fiscale",
        email: "Email",
        password: "Password",
        clearPassword: "Password",
        sdiCode: "Codice SDI",
        pec: "PEC",
        notes: "Note",
        telephone: "Telefono",
        address: "Indirizzo",
        city: "Città",
        zip: "CAP",
        province: "Provincia",
        country: "Paese",
        contactPerson: "Persona di contatto",
      },
    },
    Suppliers: {
      name: "Fornitore |||| Fornitori",
      fields: {
        name: "Nome",
        vat: "Partita IVA",
        fiscalCode: "Codice fiscale",
        email: "Email",
        password: "Password",
        sdiCode: "Codice SDI",
        pec: "PEC",
        notes: "Note",
        minQty: "Importo minimo",
      },
    },
    Products: {
      name: "Prodotto |||| Prodotti",
      fields: {
        description: "Descrizione",
        minQty: "Quantità minima",
        boxQty: "Quantità per collo",
        code: "Codice",
        ean: "EAN",
        categoryId: "Categoria",
        supplierId: "Fornitore",
        hidden: "Nascosto",
        isDeleted: "Eliminato",
        parentProductId: "Prodotto padre",
        promo: "Offerta",
        advised: "Consigliato",
      },
    },
    SupplierAssociations: {
      name: "Fornitore associato |||| Fornitori associati",
      fields: {
        referralPerson: "Agente di riferimento",
        supplierId: "Fornitore",
        customerId: "Cliente",
        operatorId: "Agente di riferimento",
        paymentMethodIds: "Metodi di pagamento",
      },
    },
    Operators: {
      name: "Agente |||| Agenti",
      fields: {
        name: "Nome",
        surname: "Cognome",
        email: "Email",
        password: "Password",
        telephone: "Telefono",
        clearPassword: "Password",
      },
    },
    Attachments: {
      name: "Allegato |||| Allegati",
      fields: {
        url: "Indirizzo",
        path: "Percorso",
        filename: "Nome file",
        title: "Titolo",
        type: "Tipo",
        details: "Descrizione",
      },
    },
    Locations: {
      name: "Sede |||| Sedi",
      fields: {
        firstLine: "Intestazione",
        secondLine: "Intestazione (seconda linea)",
        address: "Indirizzo",
        city: "Città",
        zip: "CAP",
        province: "Provincia",
        country: "Paese",
        contactPerson: "Persona di contatto",
        notes: "Note",
        originId: "Cliente",
      },
    },
    Orders: {
      name: "Ordine |||| Ordini",
      fields: {
        createdAt: "Data",
        customerId: "Cliente",
        supplierId: "Fornitore",
        draft: "Ordine in bozza",
        paymentMethod: "Modalità pagamento",
        destinationId: "Sede",
        items: "Prodotti",
      },
    },
    OrderLines: {
      name: "Riga ordine |||| Righe ordine",
      fields: {
        product: "Prodotto",
        ean: "Codice EAN",
        code: "Codice",
        qty: "Quantità",
        price: "Prezzo",
      },
    },
    PaymentMethods: {
      name: "Metodo di pagamento |||| Metodi di pagamento",
      fields: {
        name: "Nome",
      },
    },
    Categories: {
      name: "Categoria prodotto |||| Categorie prodotto",
      fields: {
        name: "Nome",
        supplierId: "Fornitore",
        orderNumber: "Progressivo ordine",
      },
    },
    CatalogPrices: {
      name: "Prezzo |||| Prezzi",
      fields: {
        catalogId: "Catalogo",
        createdAt: "Data",
        price: "Prezzo",
        productId: "Prodotto",
        discounted: "Scontato",
      },
    },
    Catalogs: {
      name: "Catalogo |||| Cataloghi",
      fields: {
        name: "Nome listino",
        createdAt: "Data",
        supplierId: "Fornitore",
        items: "Prezzi",
      },
    },
  },
})
