import { Datagrid, EditButton, List, SearchInput, TextField } from "react-admin"

const CustomerList = () => {
  return (
    <List
      filters={[<SearchInput source="q" alwaysOn key="search" />]}
      exporter={false}
      sort={{ field: "name", order: "ASC" }}
    >
      <Datagrid bulkActionButtons={false} rowClick="show">
        <TextField source="name" />
        <TextField source="email" />
        <TextField source="vat" />
        <TextField source="fiscalCode" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
export default CustomerList
